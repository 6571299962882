.theme.red {
  background: #b71c1c !important; }

.theme.pink {
  background: #c2185b !important; }

.theme.purple {
  background: #d32f2f !important; }

.theme.indigo {
  background: #303f9f !important; }

.theme.blue {
  background: #2962ff !important; }

.theme.light-blue {
  background: #03a9f4 !important; }

.theme.teal {
  background: #009688 !important; }

.theme.green {
  background: #388e3c !important; }

.theme.light-green {
  background: #689f38 !important; }

.theme.lime {
  background: #c0ca33 !important; }

.theme.yellow {
  background: #fbc02d !important; }

.theme.amber {
  background: #ffa000 !important; }

.theme.orange {
  background: #ef6c00 !important; }

.theme.deep-orange {
  background: #d84315 !important; }

.theme.brown {
  background: #3e2723 !important; }

.theme.grey {
  background: #424242 !important; }

.theme.blue-grey {
  background: #455a64 !important; }

.theme-before.red::before {
  background: #b71c1c !important; }

.theme-before.pink::before {
  background: #c2185b !important; }

.theme-before.purple::before {
  background: #d32f2f !important; }

.theme-before.indigo::before {
  background: #303f9f !important; }

.theme-before.blue::before {
  background: #2962ff !important; }

.theme-before.light-blue::before {
  background: #03a9f4 !important; }

.theme-before.teal::before {
  background: #009688 !important; }

.theme-before.green::before {
  background: #388e3c !important; }

.theme-before.light-green::before {
  background: #689f38 !important; }

.theme-before.lime::before {
  background: #c0ca33 !important; }

.theme-before.yellow::before {
  background: #fbc02d !important; }

.theme-before.amber::before {
  background: #ffa000 !important; }

.theme-before.orange::before {
  background: #ef6c00 !important; }

.theme-before.deep-orange::before {
  background: #d84315 !important; }

.theme-before.brown::before {
  background: #3e2723 !important; }

.theme-before.grey::before {
  background: #424242 !important; }

.theme-before.blue-grey::before {
  background: #455a64 !important; }

.site-nav {
  border-right: 1px solid #eeeeee;
  width: 15em;
  position: fixed;
  z-index: 1030;
  top: 0;
  bottom: 0;
  transition: 0.3s cubic-bezier(0.86, 0, 0.07, 1);
  box-shadow: 1px 0 2px rgba(0, 0, 0, 0.05);
  background: #ffffff;
  -webkit-transform: translate(-100%, 0);
          transform: translate(-100%, 0); }
  .site-nav.mini {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }
  @media (min-width: 992px) {
    .site-nav {
      -webkit-transform: translate(0, 0);
              transform: translate(0, 0); }
      .site-nav.mini {
        -webkit-transform: translate(-95%, 0);
                transform: translate(-95%, 0); }
        .site-nav.mini:hover {
          -webkit-transform: translate(0, 0);
                  transform: translate(0, 0); } }
  .site-nav .nav-list-container {
    height: calc(100% - 4em); }
  .site-nav .scrollarea .scrollbar-container {
    opacity: 0; }
    .site-nav .scrollarea .scrollbar-container.vertical {
      width: 15px; }
  .site-nav .scrollarea .scrollbar-container:hover {
    background: transparent; }
  .site-nav .nav-head {
    border-bottom: 2px solid #e6e6e6;
    padding: 0.8rem 1rem;
    text-align: center;
    position: relative;
    height: 4.4em;
    margin-left: -1.5rem; }
  .site-nav .nav-head svg > path {
    stroke: #2962ff; }
  .site-nav .nav-head .toggle-dot {
    width: 11px;
    height: 11px;
    border: 2px solid #2962ff;
    border-radius: 50%;
    position: absolute;
    top: 0.8rem;
    right: 0.8rem;
    cursor: pointer; }
    .site-nav .nav-head .toggle-dot.active {
      background: #2962ff; }
    .site-nav .nav-head .toggle-dot:before {
      content: '';
      position: relative;
      display: block;
      width: 400%;
      height: 400%;
      box-sizing: border-box;
      margin-left: -150%;
      margin-top: -150%;
      border-radius: 100%;
      background-color: #2962ff;
      -webkit-animation: pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
              animation: pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite; }

@-webkit-keyframes pulse-ring {
  0% {
    -webkit-transform: scale(0.33);
            transform: scale(0.33); }
  80%,
  100% {
    opacity: 0; } }

@keyframes pulse-ring {
  0% {
    -webkit-transform: scale(0.33);
            transform: scale(0.33); }
  80%,
  100% {
    opacity: 0; } }
  .site-nav .nav-head a {
    color: #212121;
    font-weight: 700;
    height: 2.5rem; }
    .site-nav .nav-head a > svg {
      position: relative;
      height: 100%;
      top: 1px; }
    .site-nav .nav-head a > strong {
      display: inline-block;
      margin-left: 0.2rem;
      letter-spacing: 2.4px;
      text-shadow: -0.5px 0px 0 #3b3b3b, 0.5px 0px 0 #3b3b3b; }
  .site-nav .nav-list-title {
    padding: 0.4rem 1.6rem;
    margin: 1.4rem 0;
    font-size: 70%;
    text-transform: uppercase;
    font-weight: bold;
    position: relative; }
    .site-nav .nav-list-title:before {
      position: absolute;
      content: '';
      width: 15px;
      height: 2px;
      bottom: 0;
      background: #2962ff; }
  .site-nav .nav-list li a {
    cursor: pointer;
    display: block;
    padding: 1.1rem 1.5rem;
    color: #353535;
    font-weight: 600;
    position: relative; }
    .site-nav .nav-list li a > .name {
      display: inline-block;
      margin-left: 0.75rem;
      text-transform: uppercase;
      font-size: 90%;
      -webkit-transform: translate(0, 1.1px);
              transform: translate(0, 1.1px); }
    .site-nav .nav-list li a > .icon-down {
      float: right;
      -webkit-transform: translate(0, 0.4rem);
              transform: translate(0, 0.4rem);
      transition: 0.15s ease-in; }
    .site-nav .nav-list li a:hover {
      color: #2962ff; }
    .site-nav .nav-list li a .icon-dashboard {
      color: #2962ff !important; }
    .site-nav .nav-list li a .badge-chart.badge {
      background: #2962ff; }
  .site-nav .nav-list li > a.active {
    font-weight: 700;
    color: #2962ff; }
    .site-nav .nav-list li > a.active > .icon-down {
      -webkit-transform: translate(0, 0.4rem) rotate(90deg);
              transform: translate(0, 0.4rem) rotate(90deg); }
  .site-nav .nav-list li.selected > a {
    font-weight: 700;
    color: #2962ff; }
    .site-nav .nav-list li.selected > a .icon-down {
      -webkit-transform: translate(0, 0.4rem) rotate(-270deg);
              transform: translate(0, 0.4rem) rotate(-270deg); }
  .site-nav .nav-list li .inner-drop {
    font-size: 90%;
    padding-left: 2.85rem;
    background: inherit;
    box-shadow: inset 0 1px 1px 0px rgba(0, 0, 0, 0.05), inset 0 -1px 1px 0 rgba(0, 0, 0, 0.05); }
  .site-nav .nav-list li .inner-drop a {
    padding: 0.9rem 1.5rem; }
  .site-nav .nav-list li .inner-drop a:hover {
    color: #2962ff; }
