.theme.red {
  background: #b71c1c !important; }

.theme.pink {
  background: #c2185b !important; }

.theme.purple {
  background: #d32f2f !important; }

.theme.indigo {
  background: #303f9f !important; }

.theme.blue {
  background: #2962ff !important; }

.theme.light-blue {
  background: #03a9f4 !important; }

.theme.teal {
  background: #009688 !important; }

.theme.green {
  background: #388e3c !important; }

.theme.light-green {
  background: #689f38 !important; }

.theme.lime {
  background: #c0ca33 !important; }

.theme.yellow {
  background: #fbc02d !important; }

.theme.amber {
  background: #ffa000 !important; }

.theme.orange {
  background: #ef6c00 !important; }

.theme.deep-orange {
  background: #d84315 !important; }

.theme.brown {
  background: #3e2723 !important; }

.theme.grey {
  background: #424242 !important; }

.theme.blue-grey {
  background: #455a64 !important; }

.theme-before.red::before {
  background: #b71c1c !important; }

.theme-before.pink::before {
  background: #c2185b !important; }

.theme-before.purple::before {
  background: #d32f2f !important; }

.theme-before.indigo::before {
  background: #303f9f !important; }

.theme-before.blue::before {
  background: #2962ff !important; }

.theme-before.light-blue::before {
  background: #03a9f4 !important; }

.theme-before.teal::before {
  background: #009688 !important; }

.theme-before.green::before {
  background: #388e3c !important; }

.theme-before.light-green::before {
  background: #689f38 !important; }

.theme-before.lime::before {
  background: #c0ca33 !important; }

.theme-before.yellow::before {
  background: #fbc02d !important; }

.theme-before.amber::before {
  background: #ffa000 !important; }

.theme-before.orange::before {
  background: #ef6c00 !important; }

.theme-before.deep-orange::before {
  background: #d84315 !important; }

.theme-before.brown::before {
  background: #3e2723 !important; }

.theme-before.grey::before {
  background: #424242 !important; }

.theme-before.blue-grey::before {
  background: #455a64 !important; }

.onboarding-form {
  width: 80%;
  margin-bottom: 2rem; }

.onboarding-body {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center; }

.onboarding-header {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  text-align: center;
  background-color: #2962ff !important;
  color: white; }
  .onboarding-header span {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    color: #2962ff;
    display: block;
    padding: 1rem;
    background: white;
    border-radius: 100%;
    margin: 1rem; }

.user-onboarding {
  background: transparent !important;
  border: none !important; }

.onboarding-steps {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center; }

.onboarding-steps-buttons {
  width: 80%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between; }
