.theme.red {
  background: #b71c1c !important; }

.theme.pink {
  background: #c2185b !important; }

.theme.purple {
  background: #d32f2f !important; }

.theme.indigo {
  background: #303f9f !important; }

.theme.blue {
  background: #2962ff !important; }

.theme.light-blue {
  background: #03a9f4 !important; }

.theme.teal {
  background: #009688 !important; }

.theme.green {
  background: #388e3c !important; }

.theme.light-green {
  background: #689f38 !important; }

.theme.lime {
  background: #c0ca33 !important; }

.theme.yellow {
  background: #fbc02d !important; }

.theme.amber {
  background: #ffa000 !important; }

.theme.orange {
  background: #ef6c00 !important; }

.theme.deep-orange {
  background: #d84315 !important; }

.theme.brown {
  background: #3e2723 !important; }

.theme.grey {
  background: #424242 !important; }

.theme.blue-grey {
  background: #455a64 !important; }

.theme-before.red::before {
  background: #b71c1c !important; }

.theme-before.pink::before {
  background: #c2185b !important; }

.theme-before.purple::before {
  background: #d32f2f !important; }

.theme-before.indigo::before {
  background: #303f9f !important; }

.theme-before.blue::before {
  background: #2962ff !important; }

.theme-before.light-blue::before {
  background: #03a9f4 !important; }

.theme-before.teal::before {
  background: #009688 !important; }

.theme-before.green::before {
  background: #388e3c !important; }

.theme-before.light-green::before {
  background: #689f38 !important; }

.theme-before.lime::before {
  background: #c0ca33 !important; }

.theme-before.yellow::before {
  background: #fbc02d !important; }

.theme-before.amber::before {
  background: #ffa000 !important; }

.theme-before.orange::before {
  background: #ef6c00 !important; }

.theme-before.deep-orange::before {
  background: #d84315 !important; }

.theme-before.brown::before {
  background: #3e2723 !important; }

.theme-before.grey::before {
  background: #424242 !important; }

.theme-before.blue-grey::before {
  background: #455a64 !important; }

.site-head {
  height: 4.4em;
  border-bottom: 2px solid #e6e6e6;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06); }
  .site-head .right-elems .wrap {
    padding: 0.5rem;
    margin-left: 1.8rem;
    cursor: pointer;
    position: relative; }
    .site-head .right-elems .wrap .dropdown-item:active {
      background: #f5f5f5;
      color: #212121; }
  .site-head .right-elems .profile {
    padding: 0; }
    .site-head .right-elems .profile img {
      width: 36px;
      height: 36px;
      border-radius: 100%; }
  .site-head .right-elems .notify .badge {
    font-size: 65%;
    position: absolute;
    top: -4px;
    right: -4px; }
  .site-head .site-search {
    position: relative; }
    .site-head .site-search input {
      font-size: 14px;
      border: none;
      border-radius: 1px;
      padding-left: 3.5rem;
      transition: 0.2s ease-in;
      padding-top: 0.9rem;
      padding-bottom: 0.9rem; }
    .site-head .site-search > svg {
      position: absolute;
      top: 0.8rem;
      left: 1rem; }
