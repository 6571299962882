.theme.red {
  background: #b71c1c !important; }

.theme.pink {
  background: #c2185b !important; }

.theme.purple {
  background: #d32f2f !important; }

.theme.indigo {
  background: #303f9f !important; }

.theme.blue {
  background: #2962ff !important; }

.theme.light-blue {
  background: #03a9f4 !important; }

.theme.teal {
  background: #009688 !important; }

.theme.green {
  background: #388e3c !important; }

.theme.light-green {
  background: #689f38 !important; }

.theme.lime {
  background: #c0ca33 !important; }

.theme.yellow {
  background: #fbc02d !important; }

.theme.amber {
  background: #ffa000 !important; }

.theme.orange {
  background: #ef6c00 !important; }

.theme.deep-orange {
  background: #d84315 !important; }

.theme.brown {
  background: #3e2723 !important; }

.theme.grey {
  background: #424242 !important; }

.theme.blue-grey {
  background: #455a64 !important; }

.theme-before.red::before {
  background: #b71c1c !important; }

.theme-before.pink::before {
  background: #c2185b !important; }

.theme-before.purple::before {
  background: #d32f2f !important; }

.theme-before.indigo::before {
  background: #303f9f !important; }

.theme-before.blue::before {
  background: #2962ff !important; }

.theme-before.light-blue::before {
  background: #03a9f4 !important; }

.theme-before.teal::before {
  background: #009688 !important; }

.theme-before.green::before {
  background: #388e3c !important; }

.theme-before.light-green::before {
  background: #689f38 !important; }

.theme-before.lime::before {
  background: #c0ca33 !important; }

.theme-before.yellow::before {
  background: #fbc02d !important; }

.theme-before.amber::before {
  background: #ffa000 !important; }

.theme-before.orange::before {
  background: #ef6c00 !important; }

.theme-before.deep-orange::before {
  background: #d84315 !important; }

.theme-before.brown::before {
  background: #3e2723 !important; }

.theme-before.grey::before {
  background: #424242 !important; }

.theme-before.blue-grey::before {
  background: #455a64 !important; }

.view-content.view-session,
.view-content.view-invoice {
  position: relative; }
  .view-content.view-session:before,
  .view-content.view-invoice:before {
    content: '';
    position: absolute;
    background: #2962ff;
    width: 100%;
    top: 0;
    left: 0;
    height: 40%; }
  .view-content.view-session .form-card,
  .view-content.view-invoice .form-card {
    width: 25rem; }
    .view-content.view-session .form-card svg > path,
    .view-content.view-invoice .form-card svg > path {
      stroke: #2962ff; }
  @media (min-width: 992px) {
    .view-content.view-session .form-card,
    .view-content.view-invoice .form-card {
      width: 28rem; } }

.social-auth {
  width: 25rem; }
  .social-auth li {
    margin-bottom: 0.5rem; }
  .social-auth li button {
    display: inline-block;
    padding: .5rem 1.2rem;
    border-radius: 4px;
    border: 1px solid;
    margin: 0;
    transition: .15s ease-in;
    width: 100%; }
    .social-auth li button span {
      margin: 0 0.5rem; }
  .social-auth li.facebook button {
    background: #3949ab;
    border-color: #3949ab;
    color: #fff; }
  .social-auth li.google button {
    background: #e53935;
    border-color: #e53935;
    color: #fff; }

.view .view-content.view-error {
  position: relative;
  padding: 0; }
  .view .view-content.view-error .display-404 {
    font-weight: 800;
    text-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.1);
    font-size: 12rem; }
  .view .view-content.view-error .display-404 > span {
    width: 12rem;
    height: 12rem;
    border-radius: 100%;
    background: #2962ff;
    display: inline-block;
    position: relative; }
    .view .view-content.view-error .display-404 > span:before {
      content: "";
      position: absolute;
      width: 88%;
      height: 88%;
      background: #fff;
      border-radius: 100%;
      top: 6%;
      left: 6%; }

.view .view-content.view-invoice .invoice-card {
  width: 25rem;
  min-width: 25rem;
  max-width: 58rem; }
  @media (min-width: 992px) {
    .view .view-content.view-invoice .invoice-card {
      width: 58rem; } }
