@import url(~react-draft-wysiwyg/dist/react-draft-wysiwyg.css);
.rdw-option-wrapper {
  height: 2rem;
  width: 2.3rem;
  margin: 0 0.2rem;
  padding: 0.1rem; }
  .rdw-option-wrapper:hover {
    box-shadow: none;
    background: #efefef; }
  .rdw-option-wrapper.rdw-option-active {
    box-shadow: none;
    background: #cecece; }

.rdw-dropdown-wrapper {
  height: 2rem; }
  .rdw-dropdown-wrapper:hover {
    box-shadow: none;
    background: #efefef; }
