.background, .blur {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1; }

.blur {
  background: #2962ff;
  opacity: 0.5; }

.home-container h5 {
  font-weight: 100; }

.home-container section {
  min-height: 100vh;
  position: relative;
  padding: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center; }

@media (min-width: 992px) {
  .home-container section.home {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center; } }

.home-container section.home .home-hero-text {
  padding: 10rem 5rem;
  color: white;
  max-width: 70rem; }
  @media (min-width: 992px) {
    .home-container section.home .home-hero-text {
      padding: 5rem; } }
  .home-container section.home .home-hero-text h3 {
    margin: 2.5rem 0;
    font-weight: 400; }
